import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../api";

import { tabList } from "../../data";
const WebApp = () => {
  const [webappsdata, setWebappData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/webappdata`);
        setWebappData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <section className="port">
        <div className="container">
          <div>
            <h1 className="text-center">Our Portfolio</h1>
            <ul className="ulcen text-center">
              {tabList.map((link, index) => (
                <li key={index}>
                  <a href={link.links}>{link.text}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="row">
            {webappsdata?.map((mobiledata, index) => (
              <div key={index} className="col-md-4 mb-4">
                <img
                  src={mobiledata?.img_url}
                  width="100%"
                  alt={mobiledata?.img_name}
                  style={{ border: "1px solid " }}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebApp;
