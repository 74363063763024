import React, { useEffect, useState } from "react";
import { homeServicesData } from "../../data";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../api";

const ServicesSection = () => {
  const [ServicesSectionData, setServicesSectionData] = useState([]);
  const [isHovered, setIsHovered] = useState(
    Array(homeServicesData.length).fill(false)
  );

  useEffect(() => {
    fetchCarouselImages();
  }, []);

  const fetchCarouselImages = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/homedata`);
      setServicesSectionData(response?.data?.services);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const handleMouseEnter = (index) => {
    const newHoverState = [...isHovered];
    newHoverState[index] = true;
    setIsHovered(newHoverState);
  };

  const handleMouseLeave = (index) => {
    const newHoverState = [...isHovered];
    newHoverState[index] = false;
    setIsHovered(newHoverState);
  };

  return (
    <>
      <section className="service1" style={{ backgroundColor: "#16324f" }}>
        <div className="container">
          <h1
            style={{
              margin: 10,
              color: "white",
              padding: 20,
              textAlign: "center",
            }}
          >
            Our Services
          </h1>
          <div className="row">
            {ServicesSectionData?.map((service, index) => (
              <>
                <div className="col-md-4" key={index}>
                  <h4 className="text-white text-center">{service?.s_title}</h4>
                  <img
                    src={service?.s_image_url}
                    alt={service?.s_title}
                    width="90%"
                    style={{
                      height: "320px",
                      objectFit: "contain",
                      animation:
                        "up-down 2s ease-in-out infinite alternate-reverse both",
                    }}
                  />

                  <div style={{ marginTop: 30 }}>
                    <p
                      className="text-white"
                      style={{ minHeight: "80px", textAlign: "center" }}
                    >
                      {service?.s_desc}
                    </p>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn1"
                      style={{
                        backgroundColor: "#0dcaf0",
                        borderRadius: 50,
                        padding: 10,
                      }}
                    >
                      <Link
                        to={service?.path}
                        className="atag"
                        style={{
                          padding: "15px",
                          color: isHovered[index] ? "#000080" : "white",
                          transition: "color 0.3s",
                        }}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                      >
                        {service?.s_title}
                      </Link>
                    </button>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesSection;
