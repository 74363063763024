import React, { useState } from "react";
import "../../style.css";
import "../../style1.css";
import { BsChatText } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import { Form, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const socialIcons = [
  {
    icon: <BsChatText className="icon5 " size="35px" />,
  },
];
const LeftStickeyBar = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  const showAlert = () => {
    setShow(true);
    Swal.fire({
      icon: "success",
      title: "Your Inquiry Successfully Sent",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm(formData);
    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      const response = await axios.post(
        "https://businessservices.kalaasource.com/inquiry.php",
        formData
      );
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
      showAlert();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: "" });
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isHovered, setIsHovered] = useState(false);

  const isContactRoute = location.pathname.toLowerCase() === "/contact";

  if (isContactRoute) return null;

  return (
    <>
      <div class="icon-bar ">
        {socialIcons.map((item, index) => (
          <a
            key={index}
            onClick={handleShow}
            style={{
              color: isHovered ? "#000080" : "white",
              transition: "color 0.3s",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {item.icon}
          </a>
        ))}

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Submit Your Inquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your first name"
                  isInvalid={!!validationErrors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formLastName" className="mt-2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your last name"
                  isInvalid={!!validationErrors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formEmail" className="mt-2">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  type="email"
                  placeholder="Enter your email"
                  isInvalid={!!validationErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formMessage" className="mt-2">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.message}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-4">
                Submit Inquiry
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default LeftStickeyBar;
