import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

const homeServicesData = [
  {
    title: "IT Support Services",

    description:
      "We at 'Kalaasource' are committed to developing business solutions to empower, enlighten, and enhance your business.",
    link: "Services",
    buttonText: "IT Services",
  },
  {
    title: "Cloud Services",
    // image: cloud2,
    description: "Streamline Your Business with Kalaasource's Cloud Solutions.",
    link: "cloudServices",
    buttonText: "Cloud Services",
  },
  {
    title: "Web Services",

    description:
      "Empower Your Online Presence with Kalaasource's Web Solutions.",
    link: "webServices",
    buttonText: "Web Services",
  },
];

const homeBusinessSectionsData = [
  {
    title: "Digital",
    description:
      "Creating digital solutions to reshape the way human beings & machines work to enhance business & lives",
    className: "div1",
  },
  {
    title: "Technology",
    description:
      "Accelerate your success using Q3’s innovative and tailored software development services",
    className: "div2",
  },
  {
    title: "Consulting",
    description:
      "Create experiences for new sources of value, digitally reinvent your operations for great efficiency",
    className: "div3",
  },
];
const policyData = [
  {
    title: "Introduction",
    content: [
      "Welcome to Kalaasource. These Terms of Service govern your use of our website. By accessing or using our website, you agree to these Terms in full. If you do not agree with any part of these Terms, please refrain from using our Website.",
    ],
  },
  {
    title: "Website Content",
    content: [
      "The content provided on our Website is for informational purposes only and does not constitute professional advice. While we strive to ensure the accuracy and timeliness of the information, we do not warrant or guarantee its completeness or reliability.",
    ],
  },
  {
    title: "Use of Website",
    content: [
      "You may use our Website for lawful purposes only. You agree not to engage in any activity that could harm the Website, impair its functionality, or interfere with other users' access to it.",
    ],
  },
];

const careersCardData = [
  {
    text: "Having joined Q3 Technologies as a developer, I have had the opportunity to perform various roles, including Developer, Team Lead, and Technical Lead. I have successfully met the challenges and expectations of this dynamic organization. To summarize my experience here in one word, it has been 'Amazing'.",
    employeeName: "Gaurav Kothari",
  },
  {
    text: "Q3 has been a wonderful company starting out. It is an extremely fast-growing company and a wonderful place to work! The CEO truly cares about each individual, welcomes creativity, and leads by example. The culture at Q3 is the best I’ve ever worked for, and everyone is authentic and loves what they do.",
    employeeName: "Aman Ahuja",
  },
  {
    text: "I am associated with Q3 Technologies since 2008, and I can truly say, working with Q3 as an employee has been a pleasure and a great experience. I was here through the initial challenges involved with starting a new project and really can’t say enough about the willingness of everyone here to lend a helping hand.",
    employeeName: "Nischal Varshney",
  },
  {
    text: "I love working at Q3 allows people to accept challenges and follow them through to excellence and an opportunity to grow with the company. The skills which people learn here can be used as people move towards their career goals. Apart from this, caring team members and a polite management encourage one to work here.",
    employeeName: "Pranav Fulzele",
  },
];

const footerData = [
  { text: "About Us", link: "/About" },
  { text: "Our Services", link: "/Services" },
  { text: "Our Portfolio", link: "/Portfolio" },
  { text: "Contact Us", link: "/Contact" },
];
const tabList = [
  { text: "All", links: "All" },
  { text: "Web Application", links: "WebApp" },
  { text: "Mobile Application", links: "Mobile" },
  { text: "Software Development", links: "Software" },
  { text: "Web", links: "Web" },
];

const socialMediaLinks = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/kalaasource.in",
    icon: <FaFacebook className="icon" size="30px" color="white" />,
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/kalaasourceitservices/",
    icon: <FaInstagram className="icon" size="30px" color="white" />,
  },
  {
    name: "Twitter",
    href: "https://twitter.com/kalasource",
    icon: <FaTwitter className="icon" size="30px" color="white" />,
  },
  {
    name: "WhatsApp",
    href: "https://api.whatsapp.com/send?phone=917499106952&text=Hello,%20%0AI%20have%20a%20question%20about%20kalasource.in",
    icon: <FaWhatsapp className="icon" size="30px" color="white" />,
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/the-kalasource-studios/",
    icon: <FaLinkedin className="icon" size="30px" color="white" />,
  },
  {
    name: "YouTube",
    href: "https://www.youtube.com/channel/UCG3Sft9VvnvBr4tZDlPXmbg",
    icon: <FaYoutube className="icon" size="33px" color="white" />,
  },
];

export {
  socialMediaLinks,
  footerData,
  careersCardData,
  policyData,
  homeBusinessSectionsData,
  homeServicesData,
  tabList,
};
