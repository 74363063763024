import React, { useEffect, useState } from "react";

import { tabList } from "../../data";
import axios from "axios";
import BASE_URL from "../../api";

const Software = () => {
  const [softwaredata, setSoftwareData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/softwaredata`);
        setSoftwareData(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <section className="port">
        <div className="container">
          <div>
            <h1 className="text-center">Our Portfolio</h1>
            <ul className="ulcen text-center">
              {tabList.map((link, index) => (
                <li key={index}>
                  <a href={link.links}>{link.text}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="row">
            {softwaredata?.map((software, index) => (
              <div key={index} className="col-md-4 mb-3">
                <img
                  src={software?.img_url}
                  width="100%"
                  alt={software?.img_name}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Software;
