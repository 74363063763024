import React from "react";
import {homeBusinessSectionsData} from "../../data"
import "../../style.css"
import "../../style1.css"
const BusinessSection = () => {
  return (
    <>
      <section style={{ padding: 60, paddingBottom: 50 }} className="sec3">
        <div className="row ">
          <div className="sec2">
            <h1>Enabling Digital Business</h1>
            <h3>Design | Develop | Transform</h3>
          </div>
        </div>
      </section>

      <section className="sec3" style={{ color: "white" }}>
        <div className="row text-center">
          {homeBusinessSectionsData.map((section, index) => (
            <div key={index} className="col-md-4">
              <div className={section.className}>
                <h1>{section.title}</h1>
                <p className="p-4">{section.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <br></br>
      
    </>
  );
};

export default BusinessSection;
