import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../style.css";
import { Link } from "react-router-dom";
import BASE_URL from "../../api";
const Expertise = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [carouselImages, setCarouselImages] = useState([]);

  useEffect(() => {
    fetchCarouselImages();
  }, []);

  const fetchCarouselImages = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/homedata`);
      // Assuming the API response has an array of image objects with the key 'img_url'
      setCarouselImages(response?.data?.carousel);
      console.log(response?.data?.carousel);
    } catch (error) {
      console.error("Error fetching carousel images:", error);
    }
  };

  const settings = {
    infinite: true,
    autoplay: true,
    speed: 900,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="bg12">
        <div className="row">
          <div className="col-md-4 text-center mt-5 ">
            <h2 style={{ padding: 30, color: "white" }}>Our Core Expertise</h2>
            <p style={{ color: "white", margin: 10 }}>
              We combine our industry experience with our ability to provide
              end-to-end & scalable solutions to implement our customers
              business ambitions.
            </p>
            <ul className="serli">
              <li>Web and Mobile Application Development.</li>
              <li>Ecommerce & Payment Gateway Integration.</li>
              <li>Outsource It Support & Cloud Services.</li>
            </ul>
            <button
              className="mt-4"
              style={{
                backgroundColor: "#0dcaf0",
                borderRadius: 50,
                padding: 10,
              }}
            >
              <Link
                className="reada"
                to="/Services"
                style={{
                  padding: "15px",
                  color: isHovered ? "#000080" : "white",
                  transition: "color 0.3s",
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                Read More
              </Link>
            </button>
          </div>

          <div className="col-md-8 Carouselit" style={{ padding: "85px" }}>
            <Slider {...settings}>
              {carouselImages?.map((image, index) => (
                <div key={index} style={{ textAlign: "center" }}>
                  <img
                    src={image?.img_url}
                    alt={image?.img_title}
                    className="sliderImg"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      <br></br>
    </>
  );
};

export default Expertise;
