import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="terms">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center"> Terms of Service </h1>
              <div>
                <p>
                  At Kalaasource, we take the privacy of our users and customers
                  seriously. This Privacy Policy outlines how we collect, use,
                  and protect your personal information when you visit and
                  interact with our website or use our IT services. By using our
                  website and services, you agree to the terms described in this
                  policy.
                </p>

                <ol className="termsli">
                  <li>
                    <span className="lihead">Introduction: </span>
                    <ul>
                      <li>
                        Welcome to Kalaasource. These Terms of Service govern
                        your use of our website. By accessing or using our
                        website, you agree to these Terms in full. If you do not
                        agree with any part of these Terms, please refrain from
                        using our Website.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Website Content:</span>
                    <ul className="">
                      <li>
                        {" "}
                        The content provided on our Website is for informational
                        purposes only and does not constitute professional
                        advice. While we strive to ensure the accuracy and
                        timeliness of the information, we do not warrant or
                        guarantee its completeness or reliability.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Use of Website:</span>
                    <ul>
                      <li>
                        {" "}
                        You may use our Website for lawful purposes only. You
                        agree not to engage in any activity that could harm the
                        Website, impair its functionality, or interfere with
                        other users' access to it.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Intellectual Property: </span>
                    <ul>
                      <li>
                        The content on our Website, including but not limited to
                        text, images, logos, trademarks, and software, is the
                        property of the Company and is protected by copyright
                        and other intellectual property laws. You may not use,
                        reproduce, modify, or distribute any content from our
                        Website without our prior written consent.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Third-Party Links:</span>
                    <ul>
                      <li>
                        {" "}
                        Our Website may contain links to third-party websites or
                        services. These links are provided for your convenience,
                        and we do not endorse or assume any responsibility for
                        the content or practices of these external sites. Your
                        interactions with third-party websites are subject to
                        their own terms and policies.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">User Accounts: </span>
                    <ul>
                      <li>
                        Some features of our Website may require you to create a
                        user account. You are responsible for maintaining the
                        confidentiality of your account credentials and for all
                        activities that occur under your account.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead"> Disclaimer of Warranties: </span>
                    <ul>
                      <li>
                        {" "}
                        Our website is provided on an "as-is" basis, without any
                        warranties or representations, express or implied. We
                        disclaim all warranties, including but not limited to
                        merchantability, fitness for a particular purpose, and
                        non-infringement.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead"> Limitation of Liability: </span>
                    <ul>
                      <li>
                        {" "}
                        In no event shall the Company be liable for any direct,
                        indirect, incidental, consequential, or punitive damages
                        arising out of your use or inability to use our Website.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead"> Indemnification: </span>
                    <ul>
                      <li>
                        {" "}
                        You agree to indemnify and hold harmless the Company,
                        its affiliates, officers, directors, employees, and
                        agents from any claims, losses, liabilities, and
                        expenses, including attorney's fees, arising out of your
                        use of our Website or violation of these Terms.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Modification of Terms: </span>
                    <ul>
                      <li>
                        {" "}
                        We reserve the right to modify these Terms at any time.
                        Updated Terms will be posted on this page, and continued
                        use of our website after the posting will constitute
                        acceptance of the modified Terms.{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">
                      {" "}
                      Governing Law and Jurisdiction:{" "}
                    </span>
                    <ul>
                      <li>
                        {" "}
                        These Terms shall be governed by and construed in
                        accordance with the laws of [Your Country/State]. Any
                        disputes arising out of or in connection with these
                        Terms shall be subject to the exclusive jurisdiction of
                        the courts of [Your Country/State].{" "}
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead"> Severability: </span>
                    <ul>
                      <li>
                        {" "}
                        If any provision of these Terms is found to be invalid,
                        illegal, or unenforceable, the remaining provisions
                        shall not be affected and shall continue to be binding.{" "}
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>

              <p>
                If you have any questions or concerns regarding these Terms,
                please contact us at &nbsp;
                <a style={{ color: "#1bb1dc" }} href="">
                  info@kalasource.com
                </a>{" "}
                . Thank you for visiting our website!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Terms;
