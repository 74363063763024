import React, { useEffect, useState } from "react";
import "../../style.css";
import "../../style1.css";
import Modal from "react-bootstrap/Modal";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";
import Swal from "sweetalert2";
import BASE_URL from "../../api";

const Careers = () => {
  const [vacanciesData, setVacanciesData] = useState([]);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    resume: null,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const showAlert = () => {
    setShow(true);
    Swal.fire({
      icon: "success",
      title: "Your Job Application Successfully Sent",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm(formData);
    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors);
      return;
    }

    setLoading(true); // Start loading

    try {
      const formDataWithFile = new FormData();
      formDataWithFile.append("firstName", formData.firstName);
      formDataWithFile.append("lastName", formData.lastName);
      formDataWithFile.append("email", formData.email);
      formDataWithFile.append("phone", formData.phone);
      formDataWithFile.append("message", formData.message);
      formDataWithFile.append("resume", formData.resume);

      const response = await axios.post(
        "https://businessservices.kalaasource.com/php/job.php",
        // "http://localhost/kalasource-website/php/job.php",
        formDataWithFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
        resume: null,
      });
      showAlert();
      handleClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setValidationErrors({ ...validationErrors, [name]: "" });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, resume: file });
    setValidationErrors({ ...validationErrors, resume: "" });
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    if (!data.phone.trim()) {
      errors.phone = "Phone is required";
    }
    if (!data.resume) {
      errors.resume = "Resume is required";
    } else if (!/\.(pdf)$/i.test(data.resume.name)) {
      errors.resume = "Only PDF files are allowed";
    }
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchCareersData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/careersdata`);
      setVacanciesData(response?.data);
    } catch (error) {
      console.error("Error fetching careers data:", error);
    }
  };

  useEffect(() => {
    fetchCareersData();
  }, []);

  return (
    <>
      <section style={{ padding: 40 }}>
        <div className="container border-bottom">
          <div className="row mb-3">
            <h2 style={{ marginBottom: 30, textAlign: "center" }}>
              We at Kalaasource Believe
            </h2>
            {vacanciesData?.contents?.map((block, index) => (
              <div key={index} className="col-md-4">
                <img
                  src={block?.image_url}
                  width="100%"
                  alt={`Block ${index + 1}`}
                  loading="lazy"
                />
                <h5 className="text-center">{block?.title}</h5>
                <p className="text-justify">{block?.cdesc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center">Our Culture</h2>
              <p className="text-justify">
                Our philosophy is that our customers’ business interests and
                success are the single most important focus for our business.
                This is reflected in all our dealings with our customers and in
                the decisions we make on a day to day basis.Our culture of
                having an Engineering DNA, small is beautiful and our stay true,
                real and honest policy our workforce management principles are
                guided too. This applies not only to our customer experience but
                to our organization’s employee commitments as well.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="cen ">
        <div className="container  border-bottom ">
          <div className="row mb-5">
            <div className="row">
              <div className="col-sm-12">
                <div style={{ padding: 30 }}>
                  <h2 className="main-heading text-center pt-20">
                    Our Employees Speak
                  </h2>
                </div>
              </div>
              {vacanciesData?.speak?.map((testimonial, index) => (
                <div key={index} className="col-sm-3">
                  <div className="panel">
                    <div className="panel-body">
                      <p>{testimonial.exp}</p>
                      <div className="employee-name">{testimonial.name}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <div className="Email text-center">
        <div>
          <p>
            <b>Send your CV and we will contact you shortly</b>
          </p>
        </div>
        <div>
          <p>
            <b>E-mail: </b>careers@Kalasource.in
          </p>
        </div>
      </div>
      <section className="opeaning">
        <h2 className="text-center">Job openings </h2>
        <Accordion className="container mt-5 mb-5">
          <div className="row">
            {vacanciesData.length === 0 ? (
              <p>Loading...</p>
            ) : (
              vacanciesData?.jobs?.map((vacancy, index) => (
                <Accordion.Item
                  eventKey={index.toString()}
                  key={index}
                  className="col-md-12 mb-3"
                >
                  <div style={{ backgroundColor: "#0dcaf0" }}>
                    <Accordion.Header>{vacancy?.jtitle}</Accordion.Header>
                    <div style={{ backgroundColor: "white" }}>
                      <Accordion.Body className="border-bottom">
                        <span style={{ fontWeight: "bolder" }}>
                          Experience:{" "}
                        </span>
                        {vacancy.experience}
                      </Accordion.Body>
                      <Accordion.Body className="border-bottom">
                        <span style={{ fontWeight: "bolder" }}>
                          Key Skills:{" "}
                        </span>
                        {vacancy?.skill}
                      </Accordion.Body>
                      <Accordion.Body className="border-bottom">
                        <span style={{ fontWeight: "bolder" }}>
                          Job Description:{" "}
                        </span>
                        <ul>{vacancy?.jdesc}</ul>
                        {vacancy?.jd}
                      </Accordion.Body>
                      <Accordion.Body className="border-bottom">
                        <span style={{ fontWeight: "bolder" }}>Location: </span>
                        {vacancy?.location}
                      </Accordion.Body>
                      <Accordion.Body className="border-bottom">
                        <button
                          className="carbtn"
                          style={{
                            backgroundColor: "#2cc36a",
                            borderRadius: 30,
                          }}
                          onClick={handleShow}
                        >
                          Apply Now
                        </button>
                      </Accordion.Body>
                    </div>
                  </div>
                </Accordion.Item>
              ))
            )}
          </div>
        </Accordion>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton>
            <Modal.Title>Apply For The Job</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loading && (
              <div className="text-center mb-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <div>Please wait...</div>
              </div>
            )}
            {showSuccessMessage && (
              <Alert
                variant="success"
                onClose={() => setShowSuccessMessage(false)}
                dismissible
              >
                Your application has been submitted successfully.
              </Alert>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your first name"
                  isInvalid={!!validationErrors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.firstName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formLastName" className="mt-2">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  type="text"
                  placeholder="Enter your last name"
                  isInvalid={!!validationErrors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formEmail" className="mt-2">
                <Form.Label>Email </Form.Label>
                <Form.Control
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  type="email"
                  placeholder="Enter your email"
                  isInvalid={!!validationErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formPhone" className="mt-2">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter your Phone Number"
                  isInvalid={!!validationErrors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.phone}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formResume" className="mt-3">
                <Form.Label>
                  Resume{" "}
                  <span className="text-primary">
                    [Only PDF files allowed]:
                  </span>
                </Form.Label>
                <Form.Control
                  type="file"
                  className="mt-2"
                  name="resume"
                  onChange={handleFileChange}
                  isInvalid={!!validationErrors.resume}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.resume}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formMessage" className="mt-2">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  isInvalid={!!validationErrors.message}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-4">
                Apply For The Job
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};
export default Careers;
