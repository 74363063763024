import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../api";
const ItServices = () => {
  const [itService, setItServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/servicedata`);
        setItServices(response?.data.it_service);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="marb">
        <div className="container">
          <div style={{ margin: 40 }} className="text-center">
            <h2>IT Support Services</h2>
            <p>
              We at "Kalaasource" committed to developing business solutions to
              empower, enlighten, and enhance your business.
            </p>
          </div>
          {itService?.map((service, index) => (
            <div key={index} className={`row mt-${index === 0 ? 0 : 5}`}>
              <div className={`col-md-6 ${index % 2 === 0 ? "" : "ord1"}`}>
                <img
                  className="hide1"
                  alt="itservices"
                  src={service?.service_img_url}
                  width="100%"
                />
              </div>
              <div className={`col-md-6 ${index % 2 === 0 ? "ord1" : ""}`}>
                <div>
                  <h3 className="text-center">{service?.sub_title}</h3>
                  <p>{service?.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ItServices;
