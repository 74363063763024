import React, { useState } from "react";
import "../../style.css";
import "../../style1.css";
import logo from "../../assets/company logo/logo.png";
import { socialMediaLinks, footerData } from "../../data";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);

  const showAlert = () => {
    setShow(true);
    Swal.fire({
      icon: "success",
      title: "Your Mail Successfully Sent",
      showConfirmButton: false,
      timer: 2000,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    http: try {
      await axios.post(
        // "http://localhost/kalasource-website/php/newsletter.php",
        "https://businessservices.kalaasource.com/php/newsletter.php",
        {
          email,
        }
      );
      setEmail("");
      showAlert();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <section
        style={{
          backgroundColor: "black",
          paddingTop: 40,
          paddingBottom: 20,
          color: "white",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4 text-center">
              <img src={logo} width="50%" alt="logo" />
              <p
                style={{
                  color: "white",
                  paddingTop: 30,
                }}
              >
                We at "Kalaasource" committed to developing business solutions
                to "empower , enlighten and enhance your business". Our Vision
                is to digitize all small and large scale organizations and
                ultimately contribute to Digital India.
              </p>
            </div>

            <div className="col-md-4 text-center">
              <h4>Useful Links</h4>
              <ul className="footclass">
                {footerData.map((item, index) => (
                  <li key={index}>
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                ))}
              </ul>
              <form onSubmit={handleSubmit}>
                <input
                  className="txt1"
                  type="email"
                  required
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br></br>
                <button
                  className="btn1 mb-3"
                  style={{
                    backgroundColor: "#0dcaf0",
                    borderRadius: 30,
                    padding: "10px 30px",
                    color: isHovered ? "#000080" : "white",
                    transition: "color 0.3s",
                    fontWeight: "bold",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  Sign Up
                </button>
              </form>
            </div>
            <div className="col-md-4 text-center">
              <h4>Contact Us</h4>
              <p className="para mt-4">
                <span style={{ fontSize: 18 }}>Phone: </span>
                <a style={{ color: "#1bb1dc" }} href="tel:+917499106952">
                  +91 74991 06952
                </a>
              </p>
              <p className="para">
                <span style={{ fontSize: 18 }}>Email: </span>
                <a
                  style={{ color: "#1bb1dc" }}
                  href="mailto:info@kalaasource.com"
                >
                  info@kalaasource.com
                </a>
              </p>
              {socialMediaLinks.map((link, index) => (
                <Link key={index} to={link.href} target="_blank">
                  {link.icon}
                </Link>
              ))}
            </div>
          </div>
          <hr></hr>
          <div className="text-center">
            <p className="para2">
              © Copyright{" "}
              <a
                href="https://businessservices.kalaasource.com/"
                rel="noopener noreferrer"
              >
                Kalaasource.com
              </a>{" "}
              All Rights Reserved{" "}
              <span style={{ marginLeft: 10 }}>
                <Link style={{ color: "#1bb1dc" }} to="/Policy">
                  &nbsp; Privacy Policy
                </Link>{" "}
                <Link style={{ color: "#1bb1dc" }} to="/Terms">
                  &nbsp; Terms of Service
                </Link>{" "}
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
