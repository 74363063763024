import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./style.css";
import "./style1.css";
import Home from "./pages/home/home";
import About from "./pages/about/about";
// import Services from './pages/services/services';
import Portfolio from "./pages/portfolio/portfolio";
import Careers from "./pages/careers/careers";
import Contact from "./pages/contact/contact";
import ItServices from "./pages/itServices/itServices";
import All from "./pages/all/all";
import WebD from "./pages/webD/webD";
import Mobile from "./pages/mobile/mobile";
import Software from "./pages/software/software";
import Clients from "./pages/clients/clients";
import HeaderSection from "../src/components/headerSection/headerSection";
import Footer from "./components/footer/footer";
import Policy from "./pages/Policy/policy";
import Terms from "./pages/terms/terms";
import LeftStickeyBar from "./components/leftStickeyBar/leftStickeyBar";
import OutSource from "./pages/outSource/outSource";
import CloudServices from "./pages/cloudServices/cloudServices";
import WebServices from "./pages/webServices/webServices";
import WebApp from "./pages/webApp/webApp";
import Blog from "./pages/blog/blog";
const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeaderSection />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        {/* <Route path="/Services" element={<ItServices/>}/> */}
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Services" element={<ItServices />} />
        <Route path="/cloudServices" element={<CloudServices />} />
        <Route path="/webServices" element={<WebServices />} />
        <Route path="/outSourceItSupport" element={<OutSource />} />
        <Route path="/all" element={<All />} />
        <Route path="/Web" element={<WebD />} />
        <Route path="/WebApp" element={<WebApp />} />
        <Route path="/Mobile" element={<Mobile />} />
        <Route path="/Software" element={<Software />} />
        <Route path="/Clients" element={<Clients />} />
        <Route path="/Policy" element={<Policy />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
      <Footer />
      <LeftStickeyBar />
    </div>
  );
};

export default App;
