import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../api";

const Partners = () => {
  const [partners, setPartners] = useState([]);

  const fetchPartners = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/homedata`);
      setPartners(response?.data?.partners);
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  return (
    <section className="clients">
      <div className="container">
        <h2 className="text-center">Our Partners</h2>
        <div className="row">
          {partners?.map((partner, index) => (
            <div key={index} className="col-md-3">
              <img src={partner?.image_url} alt="partners" width="100%"></img>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
