import React, { useEffect, useState } from "react";
import aboutImg from "../../assets/ab.jpg";
import axios from "axios";
import BASE_URL from "../../api";

const About = () => {
  const [aboutData, setAboutData] = useState({});
  const [visionData, setVisionData] = useState({});
  const [subVisionData, setSubVisionData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/aboutdata`);
        setAboutData(response?.data?.ab_data);
        setVisionData(response?.data?.vision_data[0]);
        setSubVisionData(response?.data?.sub_vision_data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <section className="aboutHead">
        <div className="container">
          <div className="col-md-12">
            <h1 style={{ color: "white", paddingTop: 77, textAlign: "center" }}>
              "To be the leading provider of high end, best of breed product
              engineering life cycle solutions to the global software
              development and IT industry."
            </h1>
          </div>
        </div>
      </section>

      <section style={{ margin: 20, marginTop: 60 }}>
        <div className="container border border-secondary-0 rounded p-4 mb-3 ">
          <div className="row">
            <div className="col-md-5">
              <img
                className="about"
                src={aboutImg}
                alt="about"
                width="100%"
              ></img>
            </div>
            <div className="col-md-7">
              <h2 style={{ marginBottom: 20 }}>What is Kalaasource?</h2>
              <h5 className="abcol">
                We at "Kalaasource" committed to developing business solutions
                to empower , elighten and enhance your business. - Our Vision is
                to digitize all small and large scale organizations and
                ultimately contribute to Digital india.
              </h5>
              <p>
                Business is an Art and it needs Artistic and creative approach
                for its development and growth. Everyone has their own level and
                field of creativity but renowned are those who make their
                creativity a hobby, a passion or a profession , that is what we
                are. We are going to be "The pioneers of the Creative business
                approach" across the globe. We as an organization help our
                customers right from "establishing their buisness" till "do
                marketing for it" to gain recognition.
              </p>

              <h3 style={{ margin: 20, textAlign: "center" }}>
                Why to choose us...
              </h3>

              <ul className="aboutli ">
                <li>
                  We are "All in One Solution" for you Yes , we are "All in One
                  Solution" as we are having Web development , Cloud Services ,
                  Web Services , Outsource IT Support we are having all under
                  one roof. Along with this we are having Resource management
                  also.
                </li>
                <li>
                  We will always put "You" first. We at Kalaasource working with
                  customer centric approach , we strive to provide the best
                  possible customer service. Being an "Business Solution
                  provider" we are seeking to provide you an "effective
                  solution" for your Business by making you to serve your
                  customers more better. We as an organization work you with
                  transperancy.
                </li>
                <li>
                  We are working with Dynamic and Evolving Business strategy We
                  are working with Dynamic business startegy that will be
                  flexible but not rigid as per conventional models. We will be
                  working with deep research and anlyasis with standard phases
                  like Plan, Pattern, Position, Perspective and Ploy your
                  Business or product.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section style={{ margin: 40 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 style={{ margin: 20, textAlign: "center" }}>
                {visionData?.vision_title}
              </h2>
              <p className="text-justify">{visionData?.vision_description}</p>
            </div>
          </div>

          <div className="row border border-secondary-0 rounded p-4 mb-3">
            {subVisionData?.map((item) => (
              <div key={item?.id} className="col-md-4">
                <h5>{item?.sub_vision_title}</h5>
                <p>{item?.sub_vision_description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
