import React, { useEffect, useState } from "react";
import { tabList } from "../../data";

import kback from "../../assets/company logo/logo.png";
import "../../style.css";
import "../../style1.css";
import { Link } from "react-router-dom";
import axios from "axios";
import BASE_URL from "../../api";

const Portfolio = () => {
  const [imageUrls, setImageUrls] = useState([]);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchImageUrls = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/homedata`);
        // Assuming the API response contains an array of image URLs under the key 'img_url'
        if (response.data && response.data.portfolios) {
          setImageUrls(response?.data.portfolios);
        }
      } catch (error) {
        console.error("Error fetching image URLs:", error);
      }
    };

    fetchImageUrls();
  }, []);
  return (
    <>
      <section className="port">
        <div className="container">
          <div>
            <h1 className="text-center">Our Portfolio</h1>
            <ul className="ulcen text-center">
              {tabList.map((link, index) => (
                <li key={index}>
                  <a href={link.links}>{link.text}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="row">
            {imageUrls?.map((imageUrl, index) => (
              <div key={index} className="col-md-4">
                <div className="flip-card">
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={imageUrl?.image_url}
                        alt="Portfolio Image"
                        className="imgpro"
                        width="100%"
                        style={{ minHeight: "200px", objectFit: "cover" }}
                      />
                    </div>
                    <div className="flip-card-back">
                      <img src={kback} alt="Avatar" className="imgpro2" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <section className="sm text-center ">
              <div className="row">
                <div className="portbtn">
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#0dcaf0",
                      padding: 10,
                      borderRadius: 50,
                      width: 110,
                      marginTop: "200px",
                    }}
                  >
                    <Link
                      className="atag"
                      to="/all"
                      style={{
                        color: isHovered ? "#000080" : "white",
                        transition: "color 0.3s",
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      See More
                    </Link>
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
