import React, { useEffect, useState } from "react";
import axios from "axios";
import BASE_URL from "../../api";

const WebServices = () => {
  const [webService, setWebServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/servicedata`);
        setWebServices(response?.data.web_service);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="marb">
        <div className="container">
          <div style={{ margin: 40 }} className="text-center">
            <h2>Web Services</h2>
            <p>Empowering Your Online Presence with Innovative Solutions.</p>
          </div>
          {webService?.map((service, index) => (
            <div key={index} className={`row mt-${index === 0 ? 0 : 5}`}>
              <div className={`col-md-6 ${index % 2 === 0 ? "" : "ord1"}`}>
                <img
                  className="hide1"
                  alt="itservices"
                  src={service?.web_service_img_url}
                  width="80%"
                />
              </div>
              <div className={`col-md-6 ${index % 2 === 0 ? "ord1" : ""}`}>
                <div>
                  <h3 className="text-center">{service?.sub_title}</h3>
                  <p>{service?.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default WebServices;
