import React, { useEffect, useState } from "react";
import { tabList } from "../../data";
import axios from "axios";
import BASE_URL from "../../api";

const WebD = () => {
  const [webdata, setWebData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/webdata`);
        setWebData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <section className="port">
        <div className="container">
          <div>
            <h1 className="text-center">Our Portfolio</h1>
            <ul className="ulcen text-center">
              {tabList.map((link, index) => (
                <li key={index}>
                  <a href={link.links}>{link.text}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="row">
            {webdata?.map((image, index) => (
              <div key={index} className="col-md-4 mb-5">
                <div style={{ height: "200px" }}>
                  <img
                    src={image?.img_url}
                    alt={image.alt}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    loading="lazy"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default WebD;
