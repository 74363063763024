import React, { useEffect, useState } from "react";
import axios from "axios";
import { tabList } from "../../data";
import BASE_URL from "../../api";
const All = () => {
  const [alldata, setAllData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/alldata`);
        setAllData(response?.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="port1">
        <div className="container">
          <div>
            <h1 className="text-center">Our Portfolio</h1>
            <ul className="ulcen text-center">
              {tabList?.map((link, index) => (
                <li key={index}>
                  <a href={link?.links}>{link?.text}</a>
                </li>
              ))}
            </ul>
          </div>
          <div className="row">
            {alldata?.map((mobiledata, index) => (
              <div key={index} className="col-md-4 ">
                <img
                  src={mobiledata?.img_url}
                  width="100%"
                  alt={mobiledata?.img_name}
                  height="250px"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default All;
