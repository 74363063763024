import React, { useEffect } from "react";
const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="terms">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="text-center"> Terms and conditions </h1>

              <div>
                <p>
                  At Kalaasource, we take the privacy of our users and customers
                  seriously. This Privacy Policy outlines how we collect, use,
                  and protect your personal information when you visit and
                  interact with our website or use our IT services. By using our
                  website and services, you agree to the terms described in this
                  policy.
                </p>

                <ol className="termsli">
                  <li>
                    {" "}
                    <span className="lihead">Information We Collect:</span>
                    <ul>
                      <li>
                        Personal Information: This includes your name, email
                        address, phone number, and any other information you
                        voluntarily provide to us when you fill out contact
                        forms, subscribe to newsletters, or request our
                        services.
                      </li>
                      <li>
                        Usage Information: We may collect non-personal
                        information, such as IP addresses, browser type, device
                        information, and browsing behavior, using cookies and
                        similar technologies.
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">
                      How We Use Your Information:{" "}
                    </span>
                    <p>We use your information for the following purposes:</p>
                    <ul className="">
                      <li>To provide and improve our IT services to you.</li>
                      <li>
                        To respond to your inquiries and communicate with you.
                      </li>
                      <li>
                        To send you relevant newsletters, updates, and
                        promotional materials if you have subscribed to them.
                      </li>
                      <li>
                        To personalize your experience on our website and tailor
                        our content to your interests.
                      </li>
                      <li>
                        To analyze website usage and trends to enhance user
                        experience and optimize our website's performance.
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Information Sharing:</span>
                    <ul>
                      <li>
                        We do not sell, trade, or transfer your personal
                        information to third parties without your explicit
                        consent. However, we may share your information with
                        trusted service providers who assist us in operating our
                        website and delivering services to you. These
                        third-party providers are obligated to keep your
                        information confidential and are not allowed to use it
                        for any other purpose.
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Data Security:</span>
                    <ul>
                      <li>
                        We employ industry-standard security measures to protect
                        your personal information from unauthorized access,
                        alteration, or disclosure. However, please be aware that
                        no method of data transmission over the internet or
                        electronic storage is 100% secure, and we cannot
                        guarantee its absolute security.
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Third-Party Links:</span>
                    <ul>
                      <li>
                        Our website may contain links to third-party websites or
                        services. We are not responsible for the privacy
                        practices or content of these external sites. We
                        recommend reviewing the privacy policies of those
                        third-party websites before providing any personal
                        information.
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">Children's Privacy:</span>
                    <ul>
                      <li>
                        Our services are not intended for children under the age
                        of 13. We do not knowingly collect or store personal
                        information from children under 13. If you believe that
                        we have inadvertently collected personal information
                        from a child under 13, please contact us to have it
                        removed.
                      </li>
                    </ul>
                  </li>
                  <br></br>

                  <li>
                    <span className="lihead">
                      Changes to the Privacy Policy:
                    </span>
                    <ul>
                      <li>
                        We may update this Privacy Policy from time to time to
                        reflect changes in our practices or for other
                        operational, legal, or regulatory reasons. We will
                        notify you of any significant changes by posting the
                        revised policy on our website or through other
                        appropriate means.
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>

              <p>
                If you have any questions or concerns regarding this Privacy
                Policy, please contact us at{" "}
                <a style={{ color: "#1bb1dc" }} href="">
                  info@kalasource.com
                </a>{" "}
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Policy;
