import React, { useState, useEffect } from "react";
import axios from "axios";
import LazyLoad from "react-lazy-load";
import BASE_URL from "../../api";

const Clients = () => {
  const [clientImages, setClientImages] = useState([]);

  // Function to fetch client data from API
  const fetchClientData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/clientdata`);
      console.log(response?.data);
      setClientImages(response?.data);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  useEffect(() => {
    fetchClientData();
  }, []);

  return (
    <>
      <h1 className="chead text-center">Our Clients</h1>
      <section className="clientrow">
        <div className="container">
          <div className="row">
            {clientImages?.map((image) => (
              <div key={image.id} className="col-sm-2 col-xs-6">
                <LazyLoad height={200} once>
                  <img
                    src={image?.client_image}
                    className="client-img"
                    width="100%"
                    alt={`Client ${image?.id}`}
                  />
                </LazyLoad>{" "}
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
