import React from "react";
import BusinessSection from "../../components/businessSection/businessSection";
import Expertise from "../../components/expertise/expertise";
import Approach from "../../components/approach/approach";
import ServicesSection from "../../components/servicesSection/servicesSection";
import Testimonials from "../../components/testimonials/testimonials";
import Partners from "../../components/partners/partners";
import Portfolio from "../portfolio/portfolio";
const Home = () => {
  return (
    <>
      <BusinessSection/>
      <Expertise/>
      <Approach/>
      <ServicesSection/>
      <Portfolio/>
      <Testimonials/>
      <Partners/>
    </>
  );
};

export default Home;
