import React, { useState } from "react";
import "../../style.css";
import "../../style1.css";
import { FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/company logo/logo.png";
import { Link } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { FiMenu } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";

const HeaderSection = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="khead">
      <div className="row">
        <div className="headbg1">
          <ul>
            <li className="li1">
              <a
                href="tel:+917499106952"
                style={{
                  listStyle: "none",
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <FaPhoneAlt
                  color="rgb(13, 202, 240)"
                  style={{ fontSize: "17px" }}
                />{" "}
                +91-7499106952
              </a>
            </li>
            <li className="myacli">
              <a
                href="mailto:info@kalaasource.com"
                style={{
                  listStyle: "none",
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <FaRegEnvelope
                  color="rgb(13, 202, 240)"
                  style={{ fontSize: "20px" }}
                />{" "}
                info@kalaasource.com
              </a>
            </li>
          </ul>
        </div>
        {/* second header  */}
        <div className="knav2">
          <Navbar
            expand="lg"
            style={{ backgroundColor: "black" }}
            expanded={expanded}
          >
            <Container>
              <img
                style={{ marginRight: "10vw", height: "6vh", maxWidth: "47%" }}
                src={logo}
                alt="kalasouce-logo"
              />
              {expanded ? (
                <RxCross2
                  aria-controls="basic-navbar-nav "
                  className="navbaricons"
                  style={{ color: "white", fontSize: "30px" }}
                  onClick={toggleNavbar}
                />
              ) : (
                <FiMenu
                  aria-controls="basic-navbar-nav "
                  className="navbaricons"
                  style={{ color: "white", fontSize: "30px" }}
                  onClick={toggleNavbar}
                />
              )}
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link>
                    <Link className="na" to="/">
                      Home
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link className="na" to="/About">
                      About Us
                    </Link>
                  </Nav.Link>
                  <NavDropdown
                    className="na"
                    title={<span className="na">Services</span>}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item className="p-2">
                      <Link className="linkn" to="/Services">
                        IT support services
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="border border-bottom-0 p-2">
                      <Link className="linkn" to="/cloudServices">
                        Cloud Services
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="border border-bottom-0 p-2">
                      <Link className="linkn" to="/webServices">
                        Web Services
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="border border-bottom-0 p-2">
                      <Link className="linkn" to="outSourceItSupport">
                        Outsource It Support
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    className="na"
                    title={<span className="na">Portfolio</span>}
                    id="basic-nav-dropdown"
                  >
                    {/* <NavDropdown.Item className=" p-2">
                      <Link className="linkn" to="/all">
                        All
                      </Link>
                    </NavDropdown.Item> */}
                    <NavDropdown.Item className="border border-bottom-0 p-2">
                      <Link className="linkn" to="/Web">
                        Web Development
                      </Link>
                    </NavDropdown.Item>

                    <NavDropdown.Item className="border border-bottom-0 p-2">
                      <Link className="linkn" to="/Mobile">
                        Mobile Application
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="border border-bottom-0 p-2">
                      <Link className="linkn" to="/Software">
                        Software Development
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link>
                    <Link className="na" to="/Clients">
                      Clients{" "}
                    </Link>
                  </Nav.Link>

                  <Nav.Link>
                    <Link className="na" to="/Careers">
                      Careers{" "}
                    </Link>
                  </Nav.Link>

                  <Nav.Link>
                    <Link className="na" to="/Contact">
                      Contact Us
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link className="na" to="/blog">
                      Blog
                    </Link>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
