import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { Form, Button } from "react-bootstrap";
import "../../style.css";
import "../../style1.css";
import axios from "axios";
import Swal from "sweetalert2";

const Contact = () => {
  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showAlert = () => {
    setShow(true);
    Swal.fire({
      icon: "success",
      title: "Your Contact Form Successfully Sent",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm(formData);
    if (Object.keys(errors).length !== 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      const response = await axios.post(
        "https://businessservices.kalaasource.com/php/server.php",
        formData
      );
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
      showAlert();
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationErrors({ ...validationErrors, [e.target.name]: "" });
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.firstName.trim()) {
      errors.firstName = "First Name is required";
    }
    if (!data.lastName.trim()) {
      errors.lastName = "Last Name is required";
    }
    if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid";
    }
    if (!data.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  return (
    <>
      <section className="contsec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1
                style={{ paddingTop: 150, color: "white", textAlign: "center" }}
              >
                Contact Us
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="locationSec">
        <div className="container">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-md-7  border border-primary-0 rounded p-5">
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formFirstName">
                  <Form.Label style={{ display: "flex" }}>
                    First Name
                  </Form.Label>
                  <Form.Control
                    className="p-2"
                    type="text"
                    placeholder="Enter your first name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    isInvalid={!!validationErrors.firstName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formLastName" className="mt-2">
                  <Form.Label style={{ display: "flex" }}>Last Name</Form.Label>
                  <Form.Control
                    className="p-2"
                    type="text"
                    placeholder="Enter your last name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    isInvalid={!!validationErrors.lastName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formEmail" className="mt-2">
                  <Form.Label style={{ display: "flex" }}>Email </Form.Label>
                  <Form.Control
                    className="p-2"
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!validationErrors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formMessage" className="mt-2">
                  <Form.Label style={{ display: "flex" }}>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter your message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    isInvalid={!!validationErrors.message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-4">
                  Submit Inquiry
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <h4 className="mt-3">Our Locations</h4>
        <div className="row">
          <div className="col-md-4 mt-4">
            <div
              className="border border-secondary-0 rounded p-4 mb-3"
              style={{ minHeight: "140px" }}
            >
              <h5>Nashik</h5>
              <p>
                1st Floor, "Gangai", Plot No.11,Survey no.23/1, Vikhe Patil
                School Rd, Vrindawan Nagar, Kamatwade, Nashik, Maharashtra -
                422010
              </p>
              <h5>Phone : +91 7499106952</h5>
              <h5>Email-info@kalaasource.com</h5>
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div
              className="border border-secondary-0 rounded p-4 mb-3"
              style={{ minHeight: "260px" }}
            >
              <h5>Pune</h5>
              <p>
                Paud Road, Bhusari Colony, Kothrud, Pune, Maharashtra 411038
              </p>
              <h5>Phone : +91 7499106952</h5>
              <h5>Email-info@kalaasource.com</h5>
            </div>
          </div>
          <div className="col-md-4 mt-4">
            <div
              className="border border-secondary-0 rounded p-4 mb-3"
              style={{ minHeight: "260px" }}
            >
              <h5>Mumbai</h5>
              <p>No9, Sector 2a, Airoli, Navi Mumbai, Maharashtra 400708.</p>
              <h5>Phone : +91 7499106952</h5>
              <h5>Email-info@kalaasource.com</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
