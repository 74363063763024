import React from "react";

const Testimonials = () => {
  return (
    <>
      <section className="testSec">
        <div className="container">
          <div className="row">
            <h1 style={{ margin: 40, color: "white" , textAlign:"center" }}>Testimonials</h1>
            <div className="col-md-6">
              <iframe
                width="510"
                height="300"
                src="https://www.youtube.com/embed/rWZYZfZbOic"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>

            <div className="col-md-6">
              <p style={{ color: "white", padding: 40 }}>
                Business is an Art and it needs Artistic and Kreative approach
                for its development and growth. Everyone has their own level and
                field of creativity but renowned are those who make their
                creativity a hobby, a passion or a profession , that is what we
                are. We are going to be "The pioneers of the Creative business
                approach" across the globe. We as an organization help our
                customers right from "establishing their buisness" till "do
                marketing for it" to gain recognition.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
