import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import blogging from "../../assets/blogging.jpg";
import BASE_URL from "../../api";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          // "http://localhost/v97s90m77a32k93b80/blogdata"
          `${BASE_URL}/blogdata`
        );
        if (response?.data && response?.data?.blogs) {
          setBlogs(response?.data?.blogs);
        } else {
          setError("Invalid data received from server");
        }
      } catch (error) {
        setError("Failed to fetch blogs");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const toggleExpandDescription = (id) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <Container className="mt-5">
      <Row className="pb-5 bg-white">
        <Col md={8}>
          <img
            src={blogging}
            alt="blogging"
            style={{
              height: "350px",
              animation:
                "up-down 2s ease-in-out infinite alternate-reverse both",
            }}
          />
        </Col>
        <Col md={4} xs={12} className="pb-3 mt-4   d-flex align-items-center">
          <h1
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              marginLeft: "50%",
            }}
          >
            Blog
          </h1>
        </Col>
      </Row>

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <div className="text-danger">Error: {error}</div>
      ) : (
        <Row>
          {blogs?.map((blog) => (
            <Col md={12} key={blog.id}>
              <Card className="mb-5 shadow">
                <Card.Body className="d-flex flex-column flex-md-row align-items-start align-items-md-center">
                  <Card.Img
                    style={{
                      maxWidth: "300px",
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                    variant="top"
                    src={blog?.blogImage}
                  />
                  <div className="mx-4 flex-grow-1">
                    <Card.Title className="my-4">{blog?.title}</Card.Title>
                    <Card.Text className="border-bottom pb-4">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: expandedDescriptions[blog?.id]
                            ? blog?.description
                            : blog?.description.slice(0, 500),
                        }}
                      ></div>
                      {blog?.description.length > 100 && (
                        <Button
                          onClick={() => toggleExpandDescription(blog?.id)}
                          variant="link"
                        >
                          {expandedDescriptions[blog?.id]
                            ? "Read Less"
                            : "Read More"}
                        </Button>
                      )}
                    </Card.Text>
                    <div>
                      <Card.Text className="text-secondary lead d-flex justify-content-end ">
                        {"- " + blog?.author}
                      </Card.Text>

                      <Card.Text className="pb-1 d-flex justify-content-end  ">
                        <strong>Date: </strong> {blog.createdDate.slice(0, 11)}
                        <br />
                      </Card.Text>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default Blog;
