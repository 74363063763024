import React, { useState } from "react";
import { Link } from "react-router-dom";

const Approach = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <section style={{ marginTop: 20 }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 style={{ margin: 20 }}>Our Unique Approach</h2>
              <h5>
                Let us be a creative source to your business, let us be your
                "Kalaasource".
              </h5>
              <h6 style={{ margin: 8 }}>
                Our creations simplifies your Business Operations.
              </h6>
              <p style={{ margin: 18 }}>
                We at "Kalaasource" committed to developing business solutions
                to empower , elighten and enhance your business. - Our Vision is
                to digitize all small and large scale organizations and
                ultimately contribute to Digital india.
              </p>
              <button
                className="btn1 mt-4 "
                style={{
                  backgroundColor: "#0dcaf0",
                  borderRadius: 50,
                  padding: 15,
                }}
              >
                <Link
                  className="atag "
                  to="About"
                  style={{
                    padding: "15px",
                    color: isHovered ? "#000080" : "white",
                    transition: "color 0.3s",
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  LETS HAVE A LOOK !
                </Link>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Approach;
